<template>
  <b-container fluid class="component-performance-over-time-report-chart-result-grouped">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'performance-over-time-report-chart-result-grouped'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import PerformanceOverTimeReportMixin from '../inc/PerformanceOverTimeReportMixin'

export default {
  name: 'PerformanceOverTimeReportChartResultGrouped',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, PerformanceOverTimeReportMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    activeTab: { type: String, default: 'income_groups' },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance-over-time.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }
      const labels = []
      const aDatasets = []
      const aIncomeDataset = {}
      const aExpenseDataset = {}
      const suffix = ' ' + this.currencySymbol

      // Chart options
      this.chartOptions.legend.display = true

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return aDatasets[tooltipItem.datasetIndex].label + ' ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      for (const sPeriod in this.apiData.periods) {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(this.apiData.periods[sPeriod], this.resolution))
      }

      // Income
      aIncomeDataset.label = this.$t('common.incomes')
      aIncomeDataset.type = 'bar'
      aIncomeDataset.data = []
      aIncomeDataset.backgroundColor = this.getGreen()

      for (const sPeriodIndex in this.apiData.totals.income.period_totals) {
        aIncomeDataset.data.push(this.apiData.totals.income.period_totals[sPeriodIndex])
      }

      // Expense
      aExpenseDataset.label = this.$t('common.expenses')
      aExpenseDataset.type = 'bar'
      aExpenseDataset.data = []
      aExpenseDataset.backgroundColor = this.getRed()

      for (const sPeriodIndex in this.apiData.totals.expense.period_totals) {
        aExpenseDataset.data.push(this.apiData.totals.expense.period_totals[sPeriodIndex])
      }

      aDatasets.push(aIncomeDataset)
      aDatasets.push(aExpenseDataset)

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }
    }
  },
  watch: {
    isLoaded: { // Handles period change
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    activeTab: { // Handles switch between tabs
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
