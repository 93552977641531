<template>
  <div class="component-performance-over-time-report-table-accounts">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="6">
                  <h2>{{ $t(translationPath + 'title_' + accountType ,{start_date: startDate, end_date: endDate}) }}</h2>
                </b-col>
                <b-col cols="6" class="text-right">
                  <column-toggler :columns="options" @toggle="onColumnToggle" />

                  <!-- Toggle result charts -->
                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( translationPath + 'charts.'+showChart)"
                              variant='outline-secondary'
                              class="float-right mr-3 toggleColumnWrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('account-type-chart')" v-bind:class="{ active: (showChart === 'account-type-chart') }">{{$t(translationPath + 'charts.account-type-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('account-type-chart-stacked')" v-bind:class="{ active: (showChart === 'account-type-chart-stacked') }">{{$t(translationPath + 'charts.account-type-chart-stacked')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('account-type-chart-stacked-accounts')" v-bind:class="{ active: (showChart === 'account-type-chart-stacked-accounts') }">{{$t(translationPath + 'charts.account-type-chart-stacked-accounts')}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="toggleChart('group-bar-chart')" v-bind:class="{ active: (showChart === 'group-bar-chart') }">{{$t( translationPath + 'charts.group-bar-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('group-bar-chart-per-account-group')" v-bind:class="{ active: (showChart === 'group-bar-chart-per-account-group') }">{{$t( translationPath + 'charts.group-bar-chart-per-account-group')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('group-area-chart')" v-bind:class="{ active: (showChart === 'group-area-chart') }">{{$t(translationPath + 'charts.group-area-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('group-area-chart-percentage')" v-bind:class="{ active: (showChart === 'group-area-chart-percentage') }">{{$t(translationPath + 'charts.group-area-chart-percentage')}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="toggleChart('accounts-bar-chart')" v-bind:class="{ active: (showChart === 'accounts-bar-chart') }">{{$t(translationPath + 'charts.accounts-bar-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('accounts-bar-chart-per-account')" v-bind:class="{ active: (showChart === 'accounts-bar-chart-per-account') }">{{$t(translationPath + 'charts.accounts-bar-chart-per-account')}}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>

            <div class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_' + accountType)"></div>

            <b-alert variant="warning" v-if="tableData.length <= 3" show class="my-3 col-md-9">
              {{$t(translationPath + 'no_data_' +  accountType)}}
            </b-alert>

            <b-table
              class="spirecta-simple-table performance-over-time-table mb-0"
              show-empty
              hover
              responsive
              striped
              :items="tableData"
              :fields="tableColumns"
              :filter="filter"
              :filter-included-fields="['title','title_with_code']"
              :busy="!isLoaded"
              :tbodyTrClass="tableRowSetClass"
              v-if="tableData.length > 3"
            >
              <template v-slot:table-busy><loader/></template>

              <!-- CUSTOM SLOTS -->

              <!-- FILTER -->
              <template slot="top-row">
                <td :colspan="(this.tableColumns.length)">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-search text-secondary"></i>
                    <b-form-input v-model="filter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                  </div>
                </td>
              </template>

              <!-- title slot-->
              <template v-slot:cell(title)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-if="row.item.row_type === 'account'">
                  <b-link :to="'/reports/performance/accounts/'+row.item.id+'/view'">
                    {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                  </b-link>
                </template>
                <template v-else-if="row.item.row_type === 'account_group'">
                  <b-link :id="'group-' + row.item.id" :to="'/reports/performance/account-groups/' + row.item.id + '/twelve-months-rolling'">{{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}</b-link>
                </template>
                <template v-else-if="row.item.row_type === 'part-sum'">
                  {{$t('common.partsum')}}
                </template>
                <template v-else-if="row.item.row_type === 'total'">
                  {{$t('common.total')}}
                </template>
              </template>

              <!-- Period slots -->
              <template v-slot:[gomycell(sPeriod)]="row" v-for="sPeriod in this.apiData.periods">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account_group'">{{row.unformatted}}</template>
                <template v-else-if="row.item.row_type === 'account'">
                  <b-link :to="createTransactionHref(row.item.id, sPeriod, false)" v-bind:key="sPeriod">{{row.value}}</b-link>
                </template>
                <template v-else-if="row.item.row_type === 'part-sum'">
                  <b-link :to="createTransactionHref(row.item.id, sPeriod, true)" v-bind:key="sPeriod">{{row.value}}</b-link>
                </template>
                <template v-else>
                  {{row.value}}
                </template>
              </template>

              <!-- total slot-->
              <template v-slot:cell(total)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- total_percentage slot-->
              <template v-slot:cell(total_percentage)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- average slot-->
              <template v-slot:cell(average)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{ row.value }}</template>
              </template>

              <!-- average_with_std_dev slot-->
              <template v-slot:cell(average_with_std_dev)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{amountFormatter(row.item.average)}} &plusmn; {{amountFormatter(row.item.std_dev)}}</template>
              </template>

              <!-- std_dev slot-->
              <template v-slot:cell(std_dev)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{ row.value }}</template>
              </template>

              <!-- median slot-->
              <template v-slot:cell(median)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- min slot-->
              <template v-slot:cell(min)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- max slot-->
              <template v-slot:cell(max)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- END CUSTOM SLOTS -->
            </b-table>
            <b-link href="#" @click="exportReportDataToCsv()"  class="text-red">{{$t('common.export_to_csv' )}} →</b-link>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
/* eslint-disable camelcase */
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import PerformanceOverTimeReportMixin from '../inc/PerformanceOverTimeReportMixin'
import ColumnToggler from '@/components/common/ColumnToggler'

export default {
  name: 'PerformanceOverTimeReportTableAccounts',
  mixins: [PerformanceOverTimeReportMixin],
  components: {
    Loader,
    ColumnToggler
  },
  filters: {
    formatAmount
  },
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    isLoaded: { type: Boolean, default: false },
    activeTab: { type: String, default: '' },
    accountType: { type: String, default: 'income' },
    resolution: { type: String, default: 'month' },
    selectedChart: { type: String, default: 'account-type-chart' },
    apiData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.performance.performance-over-time.table.accounts.',
      tableData: [],
      tableColumns: [],
      filter: '',
      showChart: 'account-type-chart'
    }
  },
  methods: {
    createTransactionHref (iAccountId, sPeriod, bIsAccountGroup) {
      let sPeriodStartDate = ''
      let sPeriodEndDate = ''

      if (this.resolution === 'month') {
        sPeriodStartDate = sPeriod + '-01'
        const oTmpDate = new Date(sPeriodStartDate)
        sPeriodEndDate = new Date(oTmpDate.getFullYear(), oTmpDate.getMonth() + 1, 1)
        sPeriodEndDate = sPeriodEndDate.toISOString().split('T')[0]
      } else {
        sPeriodStartDate = sPeriod + '-01-01'
        sPeriodEndDate = sPeriod + '-12-31'
      }

      const sAccountGroupTitle = bIsAccountGroup ? 'account_group_id' : 'account_id'

      return '/transactions/view?' + sAccountGroupTitle + '=' + iAccountId + '&start_date=' + sPeriodStartDate + '&end_date=' + sPeriodEndDate
    },
    toggleChart (showChart) {
      this.$root.$emit('chart-to-display', { from_tab: 'account_types', chart: showChart })
      this.showChart = showChart
    },
    onColumnToggle (data) {
      this.$emit('column-toggle', data)
    }
  },
  mounted () {
    this.showChart = this.selectedChart
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableData = this.buildTableDataRows('accounts', this.isLoaded, this.apiData, this.accountType, this.resolution)
        this.tableColumns = this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
      },
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: function () {
        this.tableData = this.buildTableDataRows('accounts', this.isLoaded, this.apiData, this.accountType, this.resolution)
        this.tableColumns = this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
      },
      deep: true,
      immediate: true
    },
    accountType: {
      handler: function () {
        this.tableData = this.buildTableDataRows('accounts', this.isLoaded, this.apiData, this.accountType, this.resolution)
        this.tableColumns = this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
      },
      deep: true,
      immediate: true
    },
    options: {
      handler: function () {
        this.tableColumns = this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-performance-over-time-report-table-accounts{
}
</style>
