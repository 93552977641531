<template>
  <div class="component-performance-over-time-report-table-result">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="6">
                  <h2>{{ $t(translationPath + 'title',{start_date: startDate, end_date: endDate}) }}</h2>
                </b-col>
                <b-col cols="6" class="text-right">
                  <!-- Toggle columns -->
                  <column-toggler :columns="options" @toggle="onColumnToggle" />

                  <!-- Toggle result charts -->
                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( translationPath + 'charts.'+showChart)"
                              variant='outline-secondary'
                              class="float-right mr-3 toggleColumnWrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('result-bar-chart')" v-bind:class="{ active: showChart === 'result-bar-chart' }">{{$t( translationPath + 'charts.result-bar-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('result-area-chart-acc')" v-bind:class="{ active: (showChart === 'result-area-chart-acc') }">{{$t(translationPath + 'charts.result-area-chart-acc')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('result-grouped-bar-chart')" v-bind:class="{ active: showChart === 'result-grouped-bar-chart' }">{{$t( translationPath + 'charts.result-grouped-bar-chart')}}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>

            <div class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_period_table_total')"></div>

            <b-table
              class="spirecta-simple-table performance-over-time-table mb-0"
              show-empty
              hover
              responsive
              striped
              :items="tableData"
              :fields="aTableColumns"
              :busy="!isLoaded"
              :tbodyTrClass="tableRowSetClass"
            >
              <template v-slot:table-busy><loader/></template>

              <!-- CUSTOM SLOTS -->
              <template v-slot:cell(title)="row">
                <template v-if="row.item.row_type === 'total'">{{ row.value }}</template>
                <template v-else><b-link href="#" @click="$emit('click-title', { tab: row.item.account_type })">{{ row.value }}</b-link></template>
              </template>

              <!-- Period slots -->
              <template v-slot:[gomycell(sPeriod)]="row" v-for="sPeriod in isLoaded ? this.apiData.periods : []">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'"></template>
                <template v-else-if="row.item.row_type === 'total'">
                  <span :class="row.unformatted >= 0 ? 'text-green' : 'text-red'" v-bind:key="sPeriod">{{row.value}}</span>
                </template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- total slot-->
              <template v-slot:cell(total)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- average slot-->
              <template v-slot:cell(average_with_std_dev)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'"></template>
                <template v-else>{{amountFormatter(row.item.average)}} &plusmn; {{amountFormatter(row.item.std_dev)}}</template>
              </template>

              <!-- average slot-->
              <template v-slot:cell(average)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- std_dev slot-->
              <template v-slot:cell(std_dev)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- median slot-->
              <template v-slot:cell(median)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- min slot-->
              <template v-slot:cell(min)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- max slot-->
              <template v-slot:cell(max)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- END CUSTOM SLOTS -->
            </b-table>
            <b-link href="#" @click="exportReportDataToCsv()"  class="text-red">{{$t('common.export_to_csv' )}} →</b-link>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState } from 'vuex'
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import PerformanceOverTimeReportMixin from '../inc/PerformanceOverTimeReportMixin'
import ColumnToggler from '@/components/common/ColumnToggler'

export default {
  name: 'PerformanceOverTimeReportTableResult',
  mixins: [PerformanceOverTimeReportMixin],
  components: {
    Loader,
    ColumnToggler
  },
  filters: {
    formatAmount
  },
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    isLoaded: { type: Boolean, default: false },
    activeTab: { type: String, default: '' },
    resolution: { type: String, default: 'month' },
    selectedChart: { type: String, default: 'account-type-chart' },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    },
    apiData: {
      type: Object,
      default: function () {
        return {
          periods: []
        }
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.performance.performance-over-time.table.result.',
      tableData: [],
      bShowAccTableRow: false,
      showChart: 'result-bar-chart'
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    aTableColumns () {
      return this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
    }
  },
  mounted () {
    this.showChart = this.selectedChart
  },
  methods: {
    buildTableDataRows2 () {
      if (!this.isLoaded) { return [] }

      // Use mixin function to build income/expense account table
      this.tableData = []
      this.tableData.push({ row_type: 'account-group', title: this.$t('common.incomes') })
      this.tableData = [...this.tableData, ...this.buildTableDataRows('account_groups', this.isLoaded, this.apiData, 'income', this.resolution)]
      this.tableData.push({ row_type: 'empty-row', title: '', average: 0, total: 0 })
      this.tableData.push({ row_type: 'account-group', title: this.$t('common.expenses') })
      this.tableData = [...this.tableData, ...this.buildTableDataRows('account_groups', this.isLoaded, this.apiData, 'expense', this.resolution)]
      this.tableData.push({ row_type: 'empty-row', title: '', average: 0, total: 0 })

      this.tableData = this.tableData.map((item) => {
        if (item.row_type === 'total') {
          item.row_type = 'subtotal'
        }
        return item
      })

      // Total row
      const oTotalRow = Object.assign({ title: this.$t('common.result'), row_type: 'total' }, this.apiData.totals.result)
      for (const index in this.apiData.periods) {
        const sPeriod = this.apiData.periods[index]
        oTotalRow[sPeriod] = this.apiData.totals.result.period_totals[sPeriod]
      }
      this.tableData.push(oTotalRow)

      for (const idx in this.tableData) {
        if (this.tableData[idx].row_type === 'account_group') {
          this.tableData[idx].row_type = 'account'
        } else {
          // console.log(this.tableData[idx].row_type)
        }
      }
    },
    toggleChart (showChart) {
      this.$root.$emit('chart-to-display', { from_tab: 'result', chart: showChart })
      this.showChart = showChart
      // this.bShowAccTableRow = (showChart === 'result-area-chart-acc')
      // this.buildTableDataRows2()
    },
    onColumnToggle (data) {
      this.$emit('column-toggle', data)
    }
  },
  watch: {
    apiData: {
      handler: 'buildTableDataRows2',
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: 'buildTableDataRows2',
      deep: true,
      immediate: true
    },
    activeTab: {
      handler: 'buildTableDataRows2',
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-performance-over-time-report-table-result{
  .performance-over-time-table{
    tr.tr-account-group td{

    }
  }
}
</style>
