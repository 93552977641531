<template>
  <b-container fluid class="reports-performance-over-time-report-chart-result">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-performance-over-time-report-chart-periods-simple'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import PerformanceOverTimeReportMixin from '../inc/PerformanceOverTimeReportMixin'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'

export default {
  name: 'PerformanceOverTimeReportChartResult',
  components: { BarChart },
  mixins: [chartOptions, PerformanceOverTimeReportMixin, defaultChartColorPalettes],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance-over-time.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      // Chart options
      const suffix = ' ' + this.currencySymbol
      const tooltipLabel = this.$t('common.result') + ': '
      this.chartOptions.legend.display = false

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return tooltipLabel + ' ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      // Chart data
      const labels = []
      const chartData = []
      const backgroundColor = []

      for (const sPeriod in this.apiData.totals.result.period_totals) {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(sPeriod, this.resolution))
        chartData.push(this.apiData.totals.result.period_totals[sPeriod].toFixed(0))
        backgroundColor.push(this.apiData.totals.result.period_totals[sPeriod] >= 0 ? this.getGreen() : this.getRed())
      }

      this.chartData = {
        labels: labels,
        datasets: [
          {
            data: chartData,
            type: 'bar',
            backgroundColor: backgroundColor
          }
        ]
      }
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
