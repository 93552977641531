<template>
  <div class="main-gradient-header-tab-menu">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="result" :class="{disabled: activeTab == 'result'}"  @click="onTabClick">
          {{ $t(this.translationPath + 'result') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="income" :class="{disabled: activeTab == 'income'}"  @click="onTabClick">
          {{ $t(this.translationPath + 'income') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="expense" :class="{disabled: activeTab == 'expense'}"  @click="onTabClick">
          {{ $t(this.translationPath + 'expense') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="filter" :class="{disabled: activeTab == 'filter'}"  @click="onTabClick">
          {{ $t(this.translationPath + 'filter') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
      default: 'Basic'
    }
  },
  name: 'PerformanceOverTimeReportSubmenu',
  data () {
    return {
      activeTab: null,
      translationPath: 'reports.performance.performance-over-time.tabs.'
    }
  },
  created () {
    this.activeTab = this.active
  },
  methods: {
    setActiveTabValue (value) {
      this.activeTab = value
    },
    emitActiveTabValue (data) {
      this.$emit('tab-switched', data)
    },
    onTabClick (event) {
      this.changeTab({ tab: event.target.attributes.tab.value })
    },
    changeTab (data) {
      this.setActiveTabValue(data.tab)
      this.emitActiveTabValue(data)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
</style>
