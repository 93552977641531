<template>
  <b-container fluid class="component-performance-over-time-report-chart-accounts">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'performance-over-time-report-chart-accounts-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import PerformanceOverTimeReportMixin from '../inc/PerformanceOverTimeReportMixin'

export default {
  name: 'PerformanceOverTimeReportChartAccounts',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, PerformanceOverTimeReportMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    chartType: { type: String, default: 'per_month' },
    activeTab: { type: String, default: 'income' },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance-over-time.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      if (this.chartType === 'per_month') {
        this.fillChartPerMonth()
      } else if (this.chartType === 'per_account') {
        this.fillChartPerAccount()
      }
    },
    /**
     * Chart with account groups as grouped on the x-axis and month values as bars.
     */
    fillChartPerAccount () {
      const accountType = this.activeTab === 'income' ? 'income' : 'expense'
      const labels = []
      const aDatasets = []
      let aPeriodDataset = {}
      let iCounter = 0
      const suffix = ' ' + this.currencySymbol
      const aColorPalette = this.getMultiHuePalette(20)

      // Chart options
      this.chartOptions.legend.display = true

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return aDatasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      for (const iGroupIndex in this.apiData.groups[accountType]) {
        for (const iAccountIndex in this.apiData.groups[accountType][iGroupIndex].accounts) {
          if (this.apiData.groups[accountType][iGroupIndex].accounts[iAccountIndex].total !== 0) {
            labels.push(this.apiData.groups[accountType][iGroupIndex].accounts[iAccountIndex].title)
          }
        }
      }

      for (const sPeriod in this.apiData.periods) {
        aPeriodDataset = {}
        aPeriodDataset.label = this.apiData.periods[sPeriod]
        aPeriodDataset.type = 'bar'
        aPeriodDataset.data = []
        aPeriodDataset.sum = 0

        for (const iGroupIndex in this.apiData.groups[accountType]) {
          for (const iAccountIndex in this.apiData.groups[accountType][iGroupIndex].accounts) {
            if (this.apiData.groups[accountType][iGroupIndex].accounts[iAccountIndex].total !== 0) {
              const iAmount = this.apiData.groups[accountType][iGroupIndex].accounts[iAccountIndex].period_totals[this.apiData.periods[sPeriod]]
              aPeriodDataset.data.push(iAmount)
              aPeriodDataset.sum += iAmount
            }
          }
        }
        aPeriodDataset.backgroundColor = aColorPalette[iCounter++]
        aDatasets.push({ ...aPeriodDataset })
      }

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }
    },
    /**
     * Chart with periods as x-labels and account groups as bars for each period
     */
    fillChartPerMonth () {
      const accountType = this.activeTab === 'income' ? 'income' : 'expense'
      const labels = []
      const aDatasets = []
      let oAccountTmpDataset = {}
      const aColor = this.getMultiHuePalette(20)
      let iColorCounter = 0
      const aDatasetLabels = []

      const suffix = ' ' + this.currencySymbol

      // Chart options
      this.chartOptions.legend.display = true

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return aDatasetLabels[tooltipItem.datasetIndex] + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      for (const sPeriod in this.apiData.periods) {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(this.apiData.periods[sPeriod], this.resolution))
      }

      for (const iGroupIndex in this.apiData.groups[accountType]) {
        for (const iAccountIndex in this.apiData.groups[accountType][iGroupIndex].accounts) {
          const aAccountData = []

          oAccountTmpDataset = {
            label: this.apiData.groups[accountType][iGroupIndex].accounts[iAccountIndex].title,
            fill: true,
            type: 'bar',
            backgroundColor: aColor[iColorCounter]
          }

          for (const iPeriodIndex in this.apiData.groups[accountType][iGroupIndex].accounts[iAccountIndex].period_totals) {
            aAccountData.push(this.apiData.groups[accountType][iGroupIndex].accounts[iAccountIndex].period_totals[iPeriodIndex])
          }

          oAccountTmpDataset.data = [...aAccountData]
          if (this.apiData.groups[accountType][iGroupIndex].accounts[iAccountIndex].total !== 0) {
            aDatasets.push({ ...oAccountTmpDataset })
            aDatasetLabels.push(oAccountTmpDataset.label)
            iColorCounter++
            if (iColorCounter >= 20) { iColorCounter = 0 }
          }
        }
      }

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }
    }
  },
  watch: {
    isLoaded: { // Handles period change
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    activeTab: { // Handles switch between tabs
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    chartType: { // Handles switch between tabs
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
