<template>
  <b-container fluid class="component-performance-over-time-report-chart-account-groups-area">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'performance-over-time-report-chart-account-groups-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
// import BarChart from '@/components/chartjs/BarChart'
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import PerformanceOverTimeReportMixin from '../inc/PerformanceOverTimeReportMixin'

export default {
  name: 'PerformanceOverTimeReportChartAccountGroupArea',
  components: { LineChart },
  mixins: [chartOptions, defaultChartColorPalettes, PerformanceOverTimeReportMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    chartType: { type: String, default: 'percentage' },
    activeTab: { type: String, default: 'income' },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance-over-time.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }
      const accountType = this.activeTab === 'income' ? 'income' : 'expense'
      const labels = []
      let aGroupDataset = {}
      const aDatasets = []
      const aColorPalette = this.getIncomeGraphPalette(8)
      let iCounter = 0
      const self = this
      let suffix = ' '

      // Chart options
      this.chartOptions.legend.display = true
      this.chartOptions.scales.yAxes[0].stacked = true

      // If we are percentage chart
      if (this.chartType === 'percentage') {
        suffix = ' %'
        this.chartOptions.scales.yAxes[0].ticks.max = 100
        this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
          return value + suffix
        }
      } else {
        suffix = ' ' + this.currencySymbol
        this.chartOptions.scales.yAxes[0].ticks.max = this.apiData.groups[accountType].max
        this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
          return value + ' ' + suffix
        }
      }

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return self.apiData.groups[accountType][tooltipItem.datasetIndex].title + ' ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      for (const sPeriod in this.apiData.periods) {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(this.apiData.periods[sPeriod], this.resolution))
      }

      for (const index in this.apiData.groups[accountType]) {
        aGroupDataset = {}
        aGroupDataset.type = 'line'
        aGroupDataset.stack = true
        aGroupDataset.data = []
        let sLabelSuffix = ''

        for (const sPeriodIndex in this.apiData.groups[accountType][index].period_totals) {
          if (this.chartType === 'amount') {
            /** Amount area chart **/
            aGroupDataset.data.push(this.apiData.groups[accountType][index].period_totals[sPeriodIndex])
          } else if (this.chartType === 'percentage') {
            /** Percentage area chart **/
            const iPeriodTotal = this.apiData.totals[accountType].period_totals[sPeriodIndex]
            if (iPeriodTotal !== 0) {
              aGroupDataset.data.push(Math.round(this.apiData.groups[accountType][index].period_totals[sPeriodIndex] / iPeriodTotal * 100).toFixed(0))
            } else {
              aGroupDataset.data.push(0)
            }
          }
        }

        if (this.chartType === 'percentage') {
          if (this.apiData.totals[accountType].total !== 0) {
            sLabelSuffix = ' (' + Math.round(this.apiData.groups[accountType][index].total / this.apiData.totals[accountType].total * 100).toFixed(0) + ' %)'
          }
        }
        aGroupDataset.label = this.apiData.groups[accountType][index].title + sLabelSuffix

        if (this.apiData.groups[accountType][index].total !== 0) {
          aGroupDataset.backgroundColor = aColorPalette[iCounter++]
          aDatasets.push({ ...aGroupDataset })
        }
      }

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }
    }
  },
  watch: {
    isLoaded: { // Handles period change
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    activeTab: { // Handles switch between tabs
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    chartType: { // Handles switch between tabs
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
